@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400&family=Work+Sans:ital,wght@0,300;0,400;0,600;1,400&display=swap');

:root {
   --background-color: #ffffff;
   --color-primary: #2196f3;
   --text-color: #000000;
   --card-color: #ffffff;
   --url-color: #147acd;

   --bright-yellow: #eeff41;
   --grey-dark: #2a2a29;
   --shadow-color: rgba(0, 0, 0, 0.2);

   --shadow-light1: #fff9;
   --shadow-light2: #e7e7e799;
   --shadow-dark1: #0002;
   --shadow-dark2: #0001;

   --input-bg: #ffffff;
   --input-text: #000000;
   --input-border: #cccccc;
   --input-placeholder: #777777;
   --button-bg: #e0e0e0;
   --button-text: #000000;
   --slider-thumb: #cccccc;
   --slider-track: #e0e0e0;

   --slider-width: 55px;
   --slider-height: 28px;
   --slider-border-radius: 34px;
   --slider-knob-size: 22px;
   --slider-knob-border-radius: 50%;
   --slider-knob-offset: 3px;
   --slider-knob-translateX: 25px;
   --slider-background: #ccc;
   --slider-checked-background: var(--grey-dark);
}

[data-theme='dark'] {
   --background-color: #111111;
   --color-primary: #073356;
   --text-color: #ffffff;
   --card-color: #222222;
   --shadow-color: rgba(255, 255, 255, 0.1);
   --url-color: #82c0f3;

   --shadow-light1: rgba(255, 255, 255, 0.05);
   --shadow-light2: rgba(255, 255, 255, 0.07);
   --shadow-dark1: rgba(0, 0, 0, 0.2);
   --shadow-dark2: rgba(0, 0, 0, 0.1);

   --input-bg: #333333;
   --input-text: #ffffff;
   --input-border: #555555;
   --input-placeholder: #aaaaaa;
   --button-bg: #4e4e4e;
   --button-text: #ffffff;
   --slider-thumb: #aaaaaa;
   --slider-track: #777777;
}
body,
html,
#root {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: var(--background-color);
   color: var(--text-color);
}

p,
div {
   font-family: 'Source Sans Pro', sans-serif;
}
a {
   color: var(--url-color);
   text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: 'Work Sans', sans-serif;
}

iframe {
   display: none;
}
